.corporate_container {
  font-family: Futura;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
  font-size: larger;
}

.banner {
  background-size: cover;
  background-position: center;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.banner-text {
  position: absolute;
  font-family: Futura;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(237, 233, 233);
  font-size: 50px !important;
  font-weight: bolder;
  text-align: center;
  padding-bottom: 10%;
}

.text {
  margin: 5%;
  text-align: justify;
  font-size: 20px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0 5%;
}

.grid-item {
  flex: 1 1 calc(33% - 20px);
  max-width: calc(33% - 20px);
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 100%;
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
}

button {
  background-color: white;
  font-family: Futura;
  color: black;
  border: 1px solid black;
  width: fit-content;
  margin: 0 auto;
  display: block;
}

.request {
  font-size: 30px;
  padding: 10px 20px; /* Adds space around the text */
  border: 2px solid black; /* Black border for minimalism */
  color: black; /* Black text color */
  text-align: center; /* Center the text */
  cursor: pointer; /* Change the cursor to a pointer on hover */
  display: inline-block; /* Makes it behave like a button */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  width: fit-content;
  margin: 0 auto;
}

.request:hover {
  /* background-color: gray; 
  color: white;  */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.image-wrapper {
  position: relative;
}

.grid.images-loaded {
  margin-left: 10%;
  margin-right: 5%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: black;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 16px;
  line-height: 1;
  z-index: 2;
}

.delete-button:hover {
  background-color: darkred;
}

.uploadPhoto {
  border: 1px solid black;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.reviews {
  width: 100%;
  max-width: 1000px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.grid-item img {
  display: none;
}

.grid-item img.loaded {
  display: block;
}

@media (max-width: 768px) {
  .grid-item {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .banner {
    height: 300px;
  }
  .banner-text {
    font-size: 60px;
    padding-bottom: 5%;
  }
}

@media (max-width: 480px) {
  .grid-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
  .banner-text {
    font-size: 40px;
    padding-bottom: 3%;
    width: 80%;
  }
}

@media (max-width: 320px) {
  .banner-text {
    font-size: 30px;
    padding-bottom: 2%;
    width: 90%;
  }
}
