.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.about {
  display: flex;
  justify-content: space-between;
  padding-top: 5%;
}

.aboutText {
  padding-right: 80px;
  max-width: 500px;
  font-size: 20px;
  text-align: left;
  font-family: Futura;
}

.aboutText a {
  text-decoration: underline;
  color: rgb(76, 75, 75);
  font-size: 20px;
}

.aboutImage {
  width: 450px;
  height: 650px;
}

.reviews {
  width: 100%;
  max-width: 1000px;
  margin-top: 20px;
}

.footer {
  background-color: lightgray;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 100%;
}

.footer a {
  margin: 20px;
}

a {
  color: black;
  text-decoration: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  .about {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 20px;
  }

  .aboutText {
    padding-right: 0;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .aboutImage {
    width: 80%;
    height: 70%;
    margin-bottom: 20px;
  }

  .footer {
    flex-direction: column;
    height: auto;
    padding: 10px 0;
  }

  .footer a {
    margin: 5px 0;
  }
}
