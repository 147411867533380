#fashionphotos {
  display: flex;
  flex-wrap: wrap;
  margin: 30px;
  gap: 10px;
  justify-content: space-around;
}

.fashion_container {
  font-family: Futura;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
  font-size: 20px;
}

.grid {
  margin-left: 10%;
  margin-right: 5%;
}

.requestF {
  font-size: 20px;
}

.requestF a {
  text-decoration: underline;
}

.text {
  text-align: center;
}

.grid-item img {
  display: none;
}

.grid-item img.loaded {
  display: block;
}
@media (max-width: 768px) {
  .banner-text {
    padding-bottom: 50px;
  }
}

@media (max-width: 480px) {
  .banner-text {
    font-size: 50px !important;
    padding-bottom: 40px;
  }
}

@media (max-width: 320px) {
  .banner-text {
    padding-bottom: 30px;
  }
}
