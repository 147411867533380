#portraitphotos {
  display: flex;
  flex-wrap: wrap;
  margin: 30px;
  gap: 10px;
  justify-content: space-around;
}

.portraits_container {
  font-family: Futura;
  font-size: 20px;
}
.grid {
  position: relative;
}

.grid-item {
  width: 100%;
  margin-bottom: 10px;
}

.image-wrapper {
  position: relative;
}

.image-wrapper img {
  width: 100%;
  display: block;
}
.request {
  margin-bottom: 20px;
}
