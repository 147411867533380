/* Existing CSS */
.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 20px;
  /* border-bottom: 1px solid black; */
  font-size: 30px;
  font-family: Futura;
  background-color: transparent;
  position: relative;
  z-index: 1000;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo-image {
  max-width: 80px;
  max-height: 80px;
  margin-top: 5px;
  /* filter: drop-shadow(1px 1px 2px white); */
}

.logo-text {
  font-size: 17px;
  color: black;
  justify-content: space-around;
  gap: 1px;
  padding-top: 7px;
  /* text-shadow: -0.5px -0.5px 0 white, 0.5px -0.5px 0 white, -0.5px 0.5px 0 white,
    0.5px 0.5px 0 white; */
}

.logo-text p {
  margin: 0; /* Remove default margin */
  line-height: 1.2; /* Optional: adjust line height */
}

.right-links {
  display: flex;
  align-items: center;
  transition: text-shadow 0.3s ease, font-weight 0.3s ease; /* Add smooth transition for both text-shadow and font-weight */
  font-weight: 400;
}

.link:hover {
  color: #333;
  transform: scale(1.02);
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.6),
    0 0 12px rgba(255, 255, 255, 0.4); /* Softer, more elegant glow */
  font-weight: 500;
}

.link {
  color: black;
  text-decoration: none;
  padding: 10px 15px;
}

.link.active {
  text-decoration: underline;
}

.logoutButton {
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
}

/* New CSS for dropdown */
.dropdown-toggle {
  display: none; /* Hide by default */
  background: none;
  border: none;
  cursor: pointer;
  font-size: larger;
  /* padding: 10px 15px; */
  justify-content: flex-end;
  /* text-shadow: -0.5px -0.5px 0 white, 0.5px -0.5px 0 white, -0.5px 0.5px 0 white,
    0.5px 0.5px 0 white; */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
    0 0 20px rgba(255, 255, 255, 0.5);
}

.links-container {
  display: flex; /* Display links by default */
  flex-direction: row;
}

.links-container.open {
  display: none; /* Hide links when dropdown is open */
}

.dropdown-menu {
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: white;
  /* position: absolute; */
  top: 90px;
  left: 0;
  width: 100%;
  border-top: 1px solid black;
}

.dropdown-menu.open {
  display: flex;
}

.dropdown-menu .link {
  padding: 10px 20px;
}
.dropdown-menu.transparent-dropdown {
  background-color: transparent; /* Completely transparent background */
  /* border-top: none;  */
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 10px;
    border-bottom: 1px solid black;
  }

  .logo-container {
    order: 0;
    margin: 3px 0;
    margin-right: 15px;
  }

  .right-links {
    order: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .link,
  .logoutButton {
    padding: 10px;
    flex: 1 0 auto;
    text-align: center;
  }

  .dropdown-toggle {
    display: block; /* Show on mobile devices */
  }

  .links-container {
    display: none; /* Hide links by default on mobile */
  }

  .links-container.open {
    display: flex; /* Show links when dropdown is open on mobile */
    flex-direction: column; /* Stack links vertically */
  }
  .dropdown-menu {
    display: flex;
  }

  /* Ensure "Contact" is visible on homepage in mobile view */
  .nav-links.home .right-links {
    display: flex;
  }

  .nav-links.home .dropdown-toggle {
    display: none;
  }
}
