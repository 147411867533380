.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90vh;
  position: relative; /* Ensure the container takes full space */
  overflow: hidden; /* Prevent overflow if necessary */
}

h1 {
  margin-top: 30px;
  color: white;
  /* text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
    1px 1px 0 black; */
  opacity: 0;
  animation: fadeInOut 6s ease-in-out forwards;
  font-size: 40px;
  font-family: Futura;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.footer-homepage {
  flex-shrink: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-homepage a {
  padding: 10px;
}

#background-container {
  position: fixed; /* Ensure it covers the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place behind content */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  background-size: cover; /* Cover the entire container */
  opacity: 1; /* Ensure it's visible */
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .footer-homepage {
    height: auto;
    padding: 10px 0; /* Add padding for better visibility */
    width: 100%;
    text-align: center;
    position: sticky;
    bottom: 0;
  }

  .footer-homepage a {
    font-size: 18px; /* Adjust font size for mobile */
  }
}
