@font-face {
  font-family: "Quiche Sans";
  src: url("../public/fonnts.com-Quiche_Sans_Regular.ttf") format("truetype"); /* Modern Browsers */

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Baskervville";
  src: url("../public/Baskervville-Regular.ttf") format("truetype"); /* Modern Browsers */

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("../public/FuturaCyrillicLight.ttf") format("truetype"); /* Modern Browsers */

  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Quiche Sans", sans-serif;
}

code {
  font-family: "Quiche Sans", sans-serif;
}
