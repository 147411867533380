.reviewContainer {
  font-family: Futura;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 20px;
}

.Reviews {
  font-size: 30px;
}
.reviewText {
  font-family: Futura;
  text-align: center;
  font-size: 20px;
  flex-grow: 1;
}

button {
  font-size: 40px;
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.prev::before {
  content: " ";
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  cursor: pointer;
  display: block;
}

.next::before {
  content: " ";
  border-right: 2px solid #000;
  border-top: 2px solid #000;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  cursor: pointer;
  display: block;
}
